.page-login {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: var(--background-color-gray);

    .wrap {
        display: flex;
        padding-top: 30px;
        height: calc(100vh);

        .left-side {
            flex: 1;
            h1 {
                padding-left: 25px;
                font-size: 28px;
                color: var(--h1-color-white);
            }
            img {
                position: relative;
                left: 50%;
                margin-left: -120px;
                top: 20vh;
            }
        }

        .right-side {
            flex: 3;
            background-color: var(--h1-color-white);
            border-radius: 36px 0 0 0;
            display: flex;
            align-items: center;
        }
    }

    .foot {
        position: absolute;
        bottom: 18px;
        width: 100vw;
        text-align: center;
        font-size: 14px;
    }
}
