.actions {
    display: flex;
    justify-content: space-between;

    img {
        cursor: pointer;
    }
}

.blog-list {
    margin-top: 15px;
}

.search-wrap {
    input {
        font-size: 16px;
        padding: 5px 8px;
        width: 400px;
        outline: none;
        border: none;
        border-bottom: 1px solid rgb(202, 202, 202);
    }
}
