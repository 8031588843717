@media screen and (min-width: 601px) {
    .page {
        display: flex;
        width: 100vw;
        height: 100vh;
        background-color: #a4bcbc;

        .left-side {
            flex: 1;
            background-color: #fff;
            padding-top: 35px;

            .header-wrap {
                display: flex;
                justify-content: center;

                .avatar {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 62px;
                    height: 62px;
                    border-radius: 15px;
                    background-color: var(--h1-color-blue);
                    color: #fff;
                    font-size: 39px;
                }
                .name {
                    font-size: 25px;
                    color: var(--h1-color-blue);
                    display: flex;
                    align-items: center;
                    margin-left: 44px;
                }
            }
        }

        .right-side {
            flex: 4;
            padding: 15px 0 0 15px;

            .side-content {
                height: calc(100vh - 15px);
                padding: 35px;
                background-color: #fff;
                border-radius: 35px 0 0 0;
                overflow-y: auto;

                .top-bar {
                    position: relative;
                    display: flex;
                    justify-content: space-between;

                    .title {
                        font-size: 24px;
                        font-weight: bold;
                    }
                }

                .search-wrap {
                    input {
                        font-size: 16px;
                        padding: 5px 8px;
                        width: 400px;
                        outline: none;
                        border: none;
                        border-bottom: 1px solid rgb(202, 202, 202);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .page {
        display: block;
        .left-side {
            display: blog;
            .header-wrap {
                padding: 5px;
                display: flex;
                justify-content: space-between;

                .avatar {
                    display: none;
                }
                .name {
                    display: none;
                }
            }
        }

        .right-side {
            display: block;

            .top-bar {
                .title {
                    padding: 5px;
                    font-size: 18px;
                    font-weight: bold;
                }
            }

            .search-wrap {
                input {
                    font-size: 16px;
                    padding: 5px 8px;
                    outline: none;
                    border: none;
                    border-bottom: 1px solid rgb(202, 202, 202);
                }
            }
        }
    }
}
