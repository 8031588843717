.tag-content-wrap {
    .top-bar {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        .title {
            font-size: 24px;
            font-weight: bold;
        }
    }
}
