body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
img,
button {
    padding: none;
    margin: none;
}

:root {
    --background-color-gray: rgba(164, 188, 188, 1);
    --background-color-white: #f2f2f2;
    --background-color-blue: #2f80ed;
    --h1-color-white: rgba(242, 242, 242, 1);
    --h1-color-black: #333333;
    --h1-color-blue: #2f80ed;
    --text-color-red: #f12626;
}

.ant-modal-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}
.ant-modal {
    top: 0px;
}
.ant-modal-content {
    border-radius: 15px;
    overflow: hidden;
    margin: 0;
}
.ant-btn {
    border-radius: 5px;
}
