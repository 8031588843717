.center-item {
    cursor: pointer;
    min-width: 350px;
    box-shadow: 0 0 2px #a4bcbc;
    border-radius: 5px;
    margin: 10px;
    display: grid;
    grid-template-columns: auto 1fr;
    overflow: hidden;

    .type {
        width: 66px;
        display: grid;
        place-items: center;
        background-color: #2f80ed;
        color: azure;
        font-size: 27px;
    }

    .content {
        margin-left: 30px;
        padding: 10px;
        display: grid;
        grid-template-rows: auto 1fr auto;
        .title {
            margin-top: 10px;
            font-size: 18px;
            font-weight: bold;
        }
        .summary {
            color: rgb(43, 43, 43);
        }
        .info {
            text-align: right;

            color: #555555;
            font-style: italic;
        }
    }
}
