.page-404 {
    width: 100%;
    height: 100vh;
    background-color: var(--background-color-gray);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    .logo-wrap {
        padding: 80px 25px 0 0;
        background-color: var(--background-color-white);
        border-radius: 36px 36px 0 0;
        width: 50vw;
        height: calc(100vh - 190px);
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        img {
            margin: 0 auto;
            width: 320px;
        }

        p {
            position: absolute;
            width: 100vw;
            text-align: center;
            bottom: 14px;
            left: 0;
            font-size: 12px;
            color: var(--h1-color-black);
        }
    }

    .h1-wrap {
        width: 100%;
        color: var(--h1-color-white);
        h1 {
            margin: 15px;
            text-align: left;
        }
    }

   
}