.create-tag-popup {
    display: flex;
    justify-content: center;
    margin: 25px 0;

    .tag-information {
        width: 320px;
    }

    input {
        margin-bottom: 15px;
    }

    .create-button {
        button {
            width: 80px;
        }
        margin-left: 15px;
    }
}
