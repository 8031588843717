.tags-table {
    .links {
        text-align: right;
        padding-right: 5px;
    }

    .link-column {
        text-align: right;

        .links {
            font-size: 12px;
        }
        .views {
            font-size: 14px;
            font-weight: bolder;
        }
    }

    .table-header {
        font-size: 14px;
        font-weight: bold;
    }
    .table-header-des {
        font-size: 12px;
    }
    .status {
        label {
            cursor: pointer;
            margin-right: 8px;
            border-radius: 15px;
            padding: 5px 12px;
        }
        .live {
            background-color: var(--background-color-blue);
            color: #fff;
        }
        .stop {
            background-color: rgb(192, 192, 192);
            color: rgb(255, 0, 0);
        }
    }
    .actions {
        display: flex;
        justify-content: space-between;

        img {
            cursor: pointer;
            margin-right: 12px;
        }
    }
}
