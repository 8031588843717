.create-tag-button {
    background-color: var(--background-color-blue);
    color: #fff;
    width: 53px;
    height: 53px;
    border-radius: 53px;
    font-size: 53px;
    line-height: 45px;

    display: flex;
    justify-content: center;
    cursor: pointer;
}
