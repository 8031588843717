.modal-view {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .title {
        margin: 15px 0;
        font-size: 22px;
        font-weight: bold;
    }
    .summary {
        margin: 15px 0;
        font-size: 16px;
    }
    .content {
        .ql-align-center {
            text-align: center;
        }
        img {
            width: 100%;
        }
    }
}
