.sign-out {
    position: fixed;
    left: 15px;
    top: 15px;
    z-index: 999;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .sign-out {
        display: none;
    }
}
