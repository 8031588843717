.login-wrap {
    position: relative;
    margin-top: -200px;
    margin-left: 300px;

    h1 {
        margin-bottom: 30px;
    }

    .step-show {
        width: 500px;
        overflow: hidden;
    }

    .login-step-wrap {
        transition: all ease 0.4s;
        position: relative;
        width: 1040px;
        display: flex;
        justify-content: space-between;
    }

    .input-wrap {
        position: relative;
        width: 500px;
        display: flex;
        justify-content: space-between;

        .email-input {
            position: relative;
            margin-right: 30px;

            img {
                position: absolute;
            }

            .auth-logo {
                top: 10px;
                left: 8px;
            }

            .notice-logo {
                cursor: pointer;
                top: 11px;
                right: 8px;
            }
        }

        input {
            height: 40px;
            width: 320px;
            border: cornflowerblue solid 1px;
            box-shadow: none;
            padding: 4px 30px;
        }

        button {
            font-size: 18px;
            color: #fff;
            cursor: pointer;
            position: relative;
            background-color: var(--background-color-blue);
            border: none;
            height: 40px;
            width: 80px;
            border-radius: 5px;
            overflow: hidden;

            img {
                position: absolute;
                top: 5px;
                left: 50%;
                width: 31px;
                height: 31px;
                margin-left: -15px;
            }
        }
    }

    .step-wrap {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    ul {
        display: flex;
        list-style: none;
        li {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            border: 1px solid #222;
            margin: 8px;
            content: ' ';
        }
        .action {
            border: none;
            border: 1px solid cornflowerblue;
            background-color: cornflowerblue;
        }
    }

    .error-message {
        color: var(--text-color-red);
    }
}
