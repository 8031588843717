@media screen and (min-width: 601px) {
    .default-navigate {
        padding: 25px 30px;

        .menu {
            display: none;
        }

        .nav-wrap {
            list-style: none;

            li {
                display: block;
                cursor: pointer;
                padding: 12px 50px;
                color: var(--h1-color-blue);
                font-size: 21px;
                margin: 25px 0;
                border-radius: 15px;
                background-color: var(--background-color-white);

                img {
                    width: 21px;
                    height: 21px;
                    margin-right: 14px;
                }
            }

            .action {
                color: #fff;
                background-color: var(--background-color-blue);
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .default-navigate {
        .nav-wrap {
            position: fixed;
            transition: all 1s ease;
            top: 0;
            width: 100vw;
            height: 100vh;
            transform: translateX(-100vw);
            background-color: #fff;
            z-index: 999;

            list-style: none;

            li {
                margin: 15px 0;
                color: var(--h1-color-white);
                background-color: var(--background-color-blue);
                padding: 5px;
                font-size: 18px;

                img {
                    width: 21px;
                    height: 21px;
                    margin-right: 14px;
                }
            }
        }
        .menu {
            color: #fff;
            padding: 8px;
            background-color: var(--background-color-blue);
            width: 80px;
            align-items: center;
            z-index: 9;
        }
    }
}
