.invite {
    display: flex;
    .email-input {
        input {
            width: 300px;
            padding: 5px 8px;
            outline: none;
            border: none;
            border-bottom: 1px rgb(186, 186, 186) solid;
        }
    }
    .invite-btn {
        button {
            margin-left: 10px;
            background-color: #2d9cdb;
            border: none;
            border-radius: 5px;
            color: #fff;
            padding: 5px 18px;
            text-align: center;
            cursor: pointer;

            img {
                margin-right: 5px;
            }
        }
    }
}
