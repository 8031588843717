.item {
    display: grid;
    grid-template-rows: 1fr auto;
    cursor: pointer;
    width: 350px;
    box-shadow: 0 0 2px #a4bcbc;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    .coverImage {
        width: 330px;
        min-height: 170px;
        overflow: hidden;
        background-color: #a4bcbc;
        border-radius: 5px;
        display: grid;
        place-items: center;

        & > .image {
            width: 100%;
        }
    }
    .content {
        display: grid;
        grid-template-rows: auto 1fr auto;
        .title {
            margin-top: 10px;
            font-size: 18px;
            font-weight: bold;
        }
        .summary {
            color: rgb(43, 43, 43);
        }
        .info {
            display: flex;
            justify-content: space-between;

            .date {
                color: #555555;
                font-style: italic;
            }
        }
    }
}
