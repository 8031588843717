.modal-editor {
    position: relative;
    width: 100vw;

    .content {
        height: 85vh;

        h3 {
            width: 120px;
            font-size: 16px;
            font-weight: bold;
        }

        .base-input {
            display: flex;
            justify-content: space-between;

            .title-wrap {
                flex: 1;
                input {
                    width: 350px;
                    padding: 5px 8px;
                }
            }

            .summary-wrap {
                flex: 3;
                input {
                    width: 600px;
                    padding: 5px 8px;
                }
            }
        }

        .content-editor {
            margin-top: 30px;
            height: 90%;
            overflow-y: scroll;
        }
    }
}
